<template>
  <v-card>
    <v-card-title>
      Import Configuration
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-radio-group v-model="typeConfig" row>
            <v-radio :value="'20000'" label="20000"></v-radio>
            <v-radio :value="'smart'" label="Smart MSI"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-autocomplete v-model="countryId" :items="countries" :label="$t('models.country.self')"
            :disabled="this.isSmart" :item-text="item => translate(item.name)" item-value="id" />
        </v-col>
        <v-col cols="6">
          <v-autocomplete v-model="sectorId" :items="sectors" :label="$t('models.sector.self')"
            :item-text="item => translate(item.name)" item-value="id" />
        </v-col>
      </v-row>
      <v-file-input single-line v-model="configFile" label="Configuration file" filled
        prepend-icon="fa-file-excel"></v-file-input>

      <upload-error :error="error" />
      <v-btn :disabled="!canUpload" color="primary" class="ma-2" v-on:click="uploadConfig()">
        <v-icon left dark>
          mdi-upload
        </v-icon>
        Import
      </v-btn>

      <div style="display: inline-block" v-if="uploadingConfig && uploadPercentage < 100">
        <v-progress-circular :value="uploadPercentage"></v-progress-circular>
        Uploading ..
      </div>
      <div style="display: inline-block" v-else-if="uploadingConfig">
        <v-progress-circular indeterminate></v-progress-circular>
        Parsing config ..
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import UploadError from "@/components/UploadError";

@Component({
  components: { UploadError },
  mixins: [formatter],
})
export default class ConfigImport extends Vue {
  configFile = null;
  countryId = null;
  sectorId = null;

  uploadPercentage = 0;
  uploadingConfig = false;

  countries = [];
  sectors = [];
  typeConfig = '20000'; //[{'key':'20000','value':'20000'},{'key':'smart','value':'Smart MSI'}];

  error = null;

  loading = true;
  isSmart = false

  created() {
    this.$api.get(`country?expand=region&per-page=0`).then(res => {
      this.countries = res.data;
      this.$api.get(`sector?per-page=0`).then(res => {
        this.sectors = res.data;
        this.loading = false;
      });
    });
  }

  get canUpload() {
    return (
      this.configFile !== null &&
      !this.uploadingConfig &&
      (this.isSmart || this.countryId) &&
      this.sectorId
    );
  }
  @Watch("typeConfig")
  disableCountrySelect() {
    if (this.typeConfig === "smart") {
      this.isSmart = true
      this.countryId = null
    } else {
      this.isSmart = false
    }
  }


  uploadConfig() {
    const state = this.$store.state;

    this.uploadingConfig = true;
    const formData = new FormData();
    const configUpload = {
      country: this.countries.find(c => c.id === this.countryId),
      sector: this.sectors.find(c => c.id === this.sectorId),
      status: "uploading",
      percent: 0,
    };
    state.configUploads.push(configUpload);
    formData.append("file", this.configFile);
    formData.append("country_id", this.countryId);
    formData.append("sector_id", this.sectorId);
    formData.append("type", this.typeConfig);

    this.error = null;
    this.$emit("started");
    this.$api
      .post("variation/import", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = configUpload.percent = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
          if (this.uploadPercentage === 100) configUpload.status = "parsing";
        }.bind(this),
      })
      .then(() => {
        configUpload.status = "done";
        this.$emit("done");

        setTimeout(() => {
          state.configUploads = state.configUploads.filter(
            cu => cu != configUpload
          );
        }, 1000);
      })
      .catch(e => {
        console.log("ERROR", e);
        this.error = e.response.data;
        configUpload.status = "failed";
        this.uploadingConfig = false;
      })
      .finally(() => {
        this.uploadingConfig = false;
      });
  }
}
</script>
